import React from 'react';
import './styles';
//import * as Popover from '@radix-ui/react-popover';

import { Menu } from './Menu';

const App = () => {
	return (
		<div className={'fwcmdk raycast'}>
			{/* <Popover.Root>
				<Popover.Trigger>Toggle popover</Popover.Trigger>

				<Popover.Content> */}
			<Menu />
			{/* </Popover.Content>
			</Popover.Root> */}
		</div>
	);
};

export default App;
