export const group_defs = {
	product: {
		e: 'invy.products',
	},
	invoice: {
		e: 'shop.invoices.view',
	},
	quote: {
		e: 'quot.quotes',
	},
	account: {
		e: 'shop.accounts.customers',
		blocked_actions: ['download'],
		special_actions: ['call', 'email'],
	},
	job: {
		e: 'shop.invoices.jobs.view',
	},
	contact: {
		e: 'shop.accounts.contacts',
	},
};

export const action_defs = {
	view: {
		title: `View`,
		url: `/x/@{e}@/f?a=view`,
	},
	edit: {
		title: `Edit`,
		url: `/x/@{e}@/f?a=view`,
	},
	create: {
		title: `Create`,
		url: `/x/@{e}@/f?a=view`,
	},
	delete: {
		title: `Delete`,
		url: `/x/@{e}@/f?a=view`,
	},
	print: {
		title: `Print`,
		url: `/x/@{e}@/f?a=view`,
	},
	download: {
		title: `Download`,
		url: `/x/@{e}@/f?a=view`,
	},
};

export const actions = [
	'view',
	'edit',
	'create',
	'delete',
	'print',
	'download',
];
export const groups = {
	product: [...actions],
	invoice: [...actions],
	quote: [...actions],
	account: [...actions],
	job: [...actions],
	contact: [...actions],
	other: [
		'help',
		{
			key: 'contact',
			handler: (a, b, c) => {
				console.log('contact handler', a, b, c);
			},
		},
	],
};

const generate = (menu) => {
	let c = [];
	Object.keys(menu).map((key) => {
		console.log(key);
		let group = menu[key];
		if (Array.isArray(group)) {
			group = {
				key,
				items: group,
				name: key,
				...(group_defs[key] || {}),
			};
			group.name = group.name || group.key;
		}
		group.items = group.items.map((ikey) => {
			let item =
				typeof ikey === 'string'
					? {
							key: ikey,
							action: ikey,
							name: `${ikey} ${group.name}`,
							...(action_defs[ikey] || {}),
					  }
					: ikey;
			item.name = item.name || item.key;
			return item;
		});
		c[c.length] = group;
	});
	return c;
};

export const menu = generate(groups);
