import React from 'react';
import { Command, useCommandState } from 'cmdk';
import { menu } from './config';

export const MenuItem = ({ group, item }) => {
	const search = useCommandState((state) => state.search);
	return (
		<Command.Item
			onSelect={(value) => {
				console.log('onSelect', { value, item, group, search });
				if (item.handler) item.handler({ value, item, group, search });
			}}
		>
			{item.name}
		</Command.Item>
	);
};

export const MenuGroup = ({ group }) => {
	return (
		<Command.Group>
			{group.items.map((item) => (
				<MenuItem key={item.key} group={group} item={item} />
			))}
		</Command.Group>
	);
};

export const Menu = () => {
	const containerElement = React.useRef(null);
	const [search, setSearch] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [value, setValue] = React.useState('');
	const [loading, setLoading] = React.useState(false);

	// Toggle the menu when ⌘K is pressed
	React.useEffect(() => {
		const down = (e) => {
			console.info('fwcmdk Menu down', {
				key: e.key,
				shiftKey: e.shiftKey,
				ctrlKey: e.ctrlKey,
				metaKey: e.metaKey,
				altKey: e.altKey,
				e,
			});
			if (e.key === 'k' && (e.metaKey || e.altKey)) {
				setOpen((open) => !open);
			}
		};

		document.addEventListener('keydown', down);
		return () => document.removeEventListener('keydown', down);
	}, []);

	return (
		<>
			<Command.Dialog
				open={open}
				onOpenChange={setOpen}
				label="Global Command Menu"
				container={containerElement.current}
				//value=""
			>
				<Command.Input value={search} onValueChange={setSearch} />
				<Command.List>
					<Command.Empty>
						No results found for {search}.
					</Command.Empty>
					{loading && <Command.Loading>Hang on…</Command.Loading>}

					{menu.map((group) => (
						<MenuGroup key={group.key} group={group} />
					))}
				</Command.List>
			</Command.Dialog>
			<div ref={containerElement} />
		</>
	);
};
