import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from "./reportWebVitals";

const Render = (element, options = {}) => {
	console.log('fwcmdk AppRender', {
		element,
		options,
		App,
	});
	if (!element) return;

	const root = ReactDOM.createRoot(element);
	root.render(
		<React.StrictMode>
			<App {...options} />
		</React.StrictMode>
	);
};

const RenderWithAppProps = (element) => {
	if (!element) {
		//throw new Error("fwcmdk: No element found to render app");
		console.warn('fwcmdk: No element found to render app');
		return;
	}
	let props = {};
	Array.prototype.slice
		.call(element.attributes)
		.filter(
			(p) =>
				p.nodeName &&
				p.nodeName !== 'app-loader' &&
				p.nodeName.match(/^app-/)
		)
		.forEach((p) => {
			props[p.nodeName.replace(/^app-/gi, '')] = p.nodeValue;
		});

	Render(element, props);
};

if (window.fwx) {
	window.fwx.app.install('fwcmdk', Render);
} else {
	RenderWithAppProps(document.getElementById('fwcmdk'));
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
